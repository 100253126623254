.paper {
  text-align: center;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 16px;
  background-image: none;
  overflow: hidden;
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px; /*rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;*/
  z-index: 0;
}

.header {
  position: relative;
}

.image_decorator {
  width: 144px;
  height: 62px;
  display: inline-block;
  background-color: currentcolor;
  mask: url(../../../assets/images/shape-avatar.svg) center center / contain no-repeat; 
  z-index: 10;
  left: 0;
  right: 0;
  bottom: -26px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  color: rgb(255, 255, 255);
}

.image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  width: 64px;
  height: 64px;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: -32px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

.image {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

.image_backgroud_filter {
  background-color: rgba(257, 177, 92, 0.5);
  top: 0;
  z-index: 8;
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
}

.image_backgroud_container {
  width: 100%;
  line-height: 0;
  display: block;
  overflow: hidden;
  position: relative;
  padding-top: calc(56.25%);
}

.image_backgroud_wrapper {
  transition: filter .3s; /*-webkit-filter .3s;*/
  inset: 0;
  line-height: 0;
  position: absolute;
  background-size: cover !important;
}

.image_backgroud {
  color: transparent;
  width: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.info_container {
  padding: 0px 8px 0px 8px;
}

.name {
  margin: 48px 0px 0px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1rem;
  height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.divider {
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin
}

.actions {
  text-align: center;
  padding: 8px 0px 8px 0px
}