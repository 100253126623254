/**/
.box_container {
    display: grid;
    gap: 24px;
    width: 100%;
  }
  
  @media screen and (min-width: 0px) {
    .box_container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media screen and (min-width: 600px) {
    .box_container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (min-width: 900px) {
    .box_container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media screen and (min-width: 1200px) {
    .box_container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* FAB button add */
  .fab_add {
    position: fixed;
    bottom: 16px;
    right: 16px;
  }
  
  /* Tootlbar container */
  .toolbar_container {
    position: sticky;
    top: 64px;
    z-index: 9;
    background-color: #F3F6FB;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 20px;
    padding: 8px 0;
  }
  
  
  @media screen and (max-width: 600px) {
    .toolbar_container {
      top: 56px;
    }
  }

/* Styles for ImageLogos */
.imagelogos_container {
  display: grid;
  gap: 20px;
  padding: 16px;
}

.imagelogos_preview_image_input {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 600px) {
  .imagelogos_container {
    grid-template-columns: repeat(1, 1fr);
  }

  .imagelogos_preview_image_input {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .imagelogos_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .imagelogos_preview_image_input {
    grid-template-columns: 200px 1fr;
  }
}