/**/
.selector_container {
  display: grid;
  gap: 20px;
  padding: 16px;
  grid-template-columns: 1fr;
}

.box_container {
  display: grid;
  gap: 20px;
  padding: 16px;
}

@media screen and (min-width: 600px) {
  .box_container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .box_container {
    grid-template-columns: repeat(2, 1fr);
  }
}