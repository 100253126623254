.card_horizontal {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.5) 0px 0px 2px 0px, rgba(145, 158, 171, 0.5) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
}

.card_horizontal_data {
  flex-grow: 1;
}