/**/
.box_container {
  display: grid;
  gap: 24px;
}

@media screen and (min-width: 0px) {
  .box_container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .box_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .box_container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .box_container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* FAB button add */
.fab_add {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* List container */
