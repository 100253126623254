.container_main {
  display: grid;
  gap: 24px;
  margin: 16px 0;
}

.media_container_image_cards {
  display: grid;
  gap: 10px;
}

.media_image_card {
  text-align: center;
  padding: 5px;
  background-color: #cbcbcb;
}

.media_image_card_selected {
  text-align: center;
  padding: 5px;
  background-color: #3698e9;
}

.media_image_card_image {
  width: 100%;
  cursor: pointer;
}

.uploadForm {
  position: sticky;
  top: 0px;
}

.deleteMessage {
  text-align: center;
  position: sticky;
  top: 0px;
}

@media screen and (min-width: 0px) {
  .container_main {
    grid-template-columns: repeat(1, 1fr);
  }

  .media_container_image_cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_main {
    grid-template-columns: repeat(2, 1fr);
  }
}

