/**/
.container_property_selector {
  display: grid;
  gap: 24px;
}

.container_cards {
  display: grid;
  gap: 24px;
  margin: 16px 0;
}

@media screen and (min-width: 0px) {
  .container_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .container_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .container_cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* FAB button add */

.fab_add {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Interactive Map */

.container_interactive_map {
  display: grid;
  gap: 24px;
  padding: 16px;
  width: 100%;
}


@media screen and (min-width: 600px) {
  .container_interactive_map {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_interactive_map {
    grid-template-columns: repeat(2, 1fr);
  }
}

.jsonEditor > div {
  height: 75vh;
}


/* Spaces */
.container_spaces_main {
  display: grid;
  gap: 24px;
  padding: 16px;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .container_spaces_main {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_spaces_main {
    grid-template-columns: 2fr 1fr;/*repeat(2, 1fr);*/
  }
}


/**** Card data spaces *****/
.card_info {
  margin: 0px;
  font-weight: 600;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: "Public Sans", sans-serif;
}

.card_image {
  text-align: center;
}

.card_status {
  padding: 5px 10px;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  font-size: 12px;
  width: min-content;
  border-radius: 9px;
}

.card_main_meters {
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
}

.card_item_meters {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 50%;
  padding: 4px 4px;
}

.card_meters {
  font-size: 20px;
}

.card_meters_text {
  font-size: 14px;
  font-weight: bold;
  color: #757575;
  text-align: center;
}

/* Styles for ImageLogos */
.imagelogos_container {
  display: grid;
  gap: 20px;
  padding: 16px;
}

.imagelogos_preview_image_input {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 600px) {
  .imagelogos_container {
    grid-template-columns: repeat(1, 1fr);
  }

  .imagelogos_preview_image_input {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .imagelogos_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .imagelogos_preview_image_input {
    grid-template-columns: 200px 1fr;
  }
}