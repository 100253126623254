/**/
.container_property_selector {
  display: grid;
  gap: 24px;
}

.container_cards {
  display: grid;
  gap: 24px;
  margin: 16px 0;
}

@media screen and (min-width: 0px) {
  .container_cards {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 600px) {
  .container_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .container_cards {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* FAB button add */
.container_interactive_map {
  display: grid;
  gap: 24px;
  padding: 16px;
  width: 100%;
}


@media screen and (min-width: 600px) {
  .container_interactive_map {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .container_interactive_map {
    grid-template-columns: repeat(2, 1fr);
  }
}

.fab_add {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.jsonEditor > div {
  height: 75vh;
}
